<template>
  <div class="load-description">
    <div class="load-resume">
      <div class="load-resume__content" :class="selectedPlan === 'insurance' ? 'more-content' : ''">
        <div class="load-resume__left">
          <div class="load-locations">
            <p
              style="margin: 0"
              v-html="getLocationsTextStyled($t('carriers.origin'), getStopName('is_pickup'))"
            ></p>
            <p
              style="margin: 0"
              v-html="getLocationsTextStyled($t('carriers.destination'), getStopName('is_dropoff'))"
            ></p>
          </div>
          <div class="load-information">
            <div class="load-name">
              <span v-if="quoteNumber"
                >{{ $t("carriers.quoteNumber") }}
                <p>{{ chosenLoad.name }}</p>
              </span>
            </div>
            <el-collapse v-model="accessorialsTab">
              <el-collapse-item :title="accessorialsCollapse" name="accessorials">
                <AccessorialsByType v-if="accessorials" :accessorials="accessorials" />
              </el-collapse-item>
            </el-collapse>
            <el-collapse v-model="lineItemsTabs">
              <el-collapse-item :title="lineItemsCollapse" name="lineItems">
                <InfoLineItems v-if="selectedLineItems" :selectedLineItems="selectedLineItems" />
              </el-collapse-item>
            </el-collapse>
            <div class="lineItems-information">
              <span
                >{{ $t("carriers.totalWeight") }}:
                <p class="lineItems-information__value">{{ formattedTotalWeight }} (lbs)</p>
              </span>
              <span>
                {{ $t("carriers.totalUnits") }}:
                <p class="lineItems-information__value">{{ handlingUnits }}</p>
              </span>
            </div>
            <div class="edit-quote">
              <button class="edit-quote--button" @click="changeView('QuoteBook')">
                <i class="ion ion-pencil-outline"></i>
                {{ $t("carriers.editQuoteDetails") }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="load-container-middle-view">
        <div class="load-resume__middle">
          <Markups v-if="isMagayaUser" :disableButtons="disableButtons" />
          <div class="selector-container">
            <span>Cargo insurance: Need additional coverage?</span>
            <CarrierPlanSelector
              @handleSelectedPlan="handleSelectedPlan"
              :loadingCargoValue="loadingCargoValue"
              :disableButtons="disableButtons"
              :cargoValue="cargoValue"
            />
            <div class="selector-container__info">
              <span>
                <i>{{ $t("carriers.messageVendorInsurance") }}</i>
              </span>
            </div>
          </div>
          <div
            class="cargo-value-container"
            v-show="selectedPlan === 'insurance' || chosenLoad.cargo_value_for_insurance"
          >
            <CargoValue
              :disabledComponent="disableButtons"
              :cargoValueLoad="chosenLoad.cargo_value_for_insurance"
              :selectedPlan="selectedPlan"
              :invalidCargoValue="invalidCargoValue"
              v-model="cargoValue"
            />
            <el-button
              class="cargo-value__button"
              plain
              @click="calculateCargoValue"
              :disabled="areRequestInProcess || disableButtons"
            >
              <div class="cargo-value__content">
                <span v-if="!loadingCargoValue">
                  {{ $t("carriers.calculate") }}
                </span>
                <i
                  v-show="!loadingCargoValue"
                  class="ion ion-information-circle-outline"
                  id="popover-cargo-value"
                ></i>
                <b-spinner
                  small
                  v-if="loadingCargoValue"
                  variant="light"
                  label="Spinning"
                ></b-spinner>
              </div>
            </el-button>
            <b-popover target="popover-cargo-value" triggers="hover" placement="bottom">
              <template #title>{{ $t("carriers.insureShipment") }}</template>
              {{ $t("carriers.insureShipmentMessage") }}
            </b-popover>
          </div>
        </div>
        <div class="load-resume__right">
          <div class="quotes-file">
            <button
              class="quotes-file__button quotes-file__button--download"
              @click="openDownloadModal()"
              :class="disableButtons ? 'disabled' : ''"
              :disabled="disableButtons"
              v-if="$options.filters.checkPermission('get:quote-file')"
            >
              <span> Download quote file</span>
            </button>
            <button
              class="quotes-file__button"
              @click="openModal()"
              :class="disableButtons ? 'disabled' : ''"
              :disabled="disableButtons"
              v-if="
                $options.filters.checkPermission('post:quote-file') &&
                tokenInfo.is_branded === false
              "
            >
              Send quote file
            </button>
            <CustomModal
              v-model="formatDocumentModal"
              size="lg"
              :centered="true"
              :hide-header="true"
              :closeOnEsc="false"
              modalTitle="Download Quotes File"
              :showOverlay="loading"
            >
              <template #modal-content>
                <DownloadQuoteFilesModal
                  class="mt-3"
                  v-model="typeOfFormat"
                  :chosenLoad="chosenLoad"
                  :carriersList="carriersListCopy"
                  @closeModal="closeModal"
                  @handleLoading="handleLoading"
                />
              </template>
            </CustomModal>
            <b-modal
              v-model="showModal"
              hide-header
              hide-footer
              size="lg"
              no-close-on-backdrop
              centered
            >
              <SendQuoteFilesModal v-model="showModal" :carriersList="carriersListCopy" />
            </b-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import onReSize from "@/Extend/onResize";
import Markups from "./Markups.vue";
import CarrierPlanSelector from "../../../../components/CarrierPlanSelector.vue";
import AccessorialsByType from "../../../../components/AccessorialsByType.vue";
import CustomModal from "../../../../components/CustomModal.vue";
import InfoLineItems from "../../../../components/LineItems.vue";
import CargoValue from "./CargoValue.vue";
import SendQuoteFilesModal from "./SendQuoteFilesModal.vue";
import modes from "../../modes";
import DownloadQuoteFilesModal from "./DownloadQuoteFilesModal.vue";
import FormatEasyPostPrices from '../../../../Extend/FormatEasyPostPrices';
import FilterEasyPostCarriers from '../../../../Extend/FilterEasyPostCarriers';

export default {
  name: "LoadResume",
  props: {
    chosenLoad: Object,
    quoteNumber: Boolean,
    disableButtons: Boolean,
    areRequestInProcess: Boolean,
  },
  components: {
    Markups,
    CargoValue,
    AccessorialsByType,
    InfoLineItems,
    SendQuoteFilesModal,
    CustomModal,
    DownloadQuoteFilesModal,
    CarrierPlanSelector,
  },
  mixins: [onReSize, FormatEasyPostPrices, FilterEasyPostCarriers],
  data() {
    return {
      screenWidth: null,
      quoteType: null,
      accessorials: null,
      accessorialsCollapse: null,
      lineItemsCollapse: null,
      accessorialsTab: [],
      lineItemsTabs: [],
      selectedLineItems: null,
      cargoValue: null,
      invalidCargoValue: false,
      loadingCargoValue: false,
      handlingUnits: 0,
      totalWeight: 0,
      showModal: false,
      loading: false,
      tokenInfo: null,
      formatDocumentModal: false,
      typeOfFormat: "",
      selectedPlan: "",
      isMagayaUser: null,
      carriersListCopy: []
    };
  },
  async created() {
    this.tokenInfo = this.$store.getters["login/getTokenInfo"];
    this.isMagayaUser = this.$store.getters["login/getTokenInfo"].magaya_guid;
    // eslint-disable-next-line operator-linebreak
    this.cargoValue =
      // eslint-disable-next-line operator-linebreak
      this.chosenLoad.load
        ? this.chosenLoad.load.cargo_value_for_insurance
        : this.chosenLoad.cargo_value_for_insurance;
    this.$store.commit("carriers/setChosenLoadCargoValue", this.cargoValue);
    if (this.chosenLoad.multiservice) {
      this.quoteType = "all-options";
    } else {
      const modeId = this.chosenLoad.mode_id;
      const modesKeys = Object.keys(modes);
      this.quoteType = modesKeys.find((key) => modes[key].modeId === modeId);
    }
    this.selectedLineItems = this.chosenLoad.line_items;
    this.accessorials = this.chosenLoad.accessorials;
    this.collapseInformation();
  },
  beforeDestroy() {
    this.$store.commit("carriers/setChosenLoadCargoValue", false);
    this.$store.commit("carriers/clearCarriers");
  },
  computed: {
    ...mapGetters({
      loadWasInsured: "load/getLoadWasInsured",
      carriersList: "carriers/getCarriers"
    }),
    formattedTotalWeight() {
      const numberValue = parseFloat(this.totalWeight);
      return numberValue.toLocaleString();
    },
  },
  watch: {
    carriersList: {
      deep: true,
      handler() {
        this.carriersListCopy = this.filterAndMergeEasyPostCarriers(this.carriersList);
        this.originalCarrierArray = [...this.carriersList];
        this.addDropOffEasypostCarriers();
      }
    }
  },
  methods: {
    addDropOffEasypostCarriers() {
      this.carriersListCopy.forEach((element) => {
        if (element.source === "easy-post" && (element.scac === "UPSN" || element.scac === "FDEG")) {
          const copyCarrier = { ...element };
          copyCarrier.carrier = `${element.carrier} (PICKUP)`;
          copyCarrier.price = this.formatEasyPostPriceWithPickup(copyCarrier);
          this.carriersListCopy.push(copyCarrier);
        }
      });
    },
    getStopName(stopType) {
      const stopData = this.chosenLoad.stops.find((stop) => stop[stopType]);
      if (!stopData.location_id) {
        return `${stopData.city},
          ${stopData.state_code} ${stopData.zip_code}`;
      }
      const stopName = this.chosenLoad.accounts.find(
        (item) => item.id === stopData.location_id
      ).name;
      return `${stopName} - ${stopData.address}`;
    },
    collapseInformation() {
      this.accessorialsCollapse = ` ${this.$t("carriers.accessorials")} (${
        this.accessorials.length
      })`;
      this.lineItemsCollapse = `${this.$t("carriers.lineItems")} (${
        this.selectedLineItems.length
      })`;
      this.selectedLineItems.forEach((el) => {
        this.handlingUnits += parseInt(el.handling_unit_count, 10);
      });
      this.selectedLineItems.forEach((el) => {
        this.totalWeight += parseInt(el.weight, 10);
      });
    },
    async calculateCargoValue() {
      this.invalidCargoValue = false;
      if (this.cargoValue < 0) {
        this.invalidCargoValue = true;
        return;
      }
      this.loadingCargoValue = true;
      this.$emit("handlingAreRequestInProcess", true);
      // await this.sleep(500);
      await this.$store.dispatch("load/updateLoad", {
        id: this.$route.params.loadId,
        body: { cargo_value_for_insurance: this.cargoValue || null },
      });
      this.$store.commit("load/updateCargoValue", this.cargoValue);
      this.$emit("handlingAreRequestInProcess", false);
      this.$store.commit("transportSelected/modifyTransportModalInformation", {
        key: "insurance",
        value: this.cargoValue,
      });
      this.$store.commit("carriers/setChosenLoadCargoValue", this.cargoValue);
      this.$store.commit("load/setLoadInsurance", true);
      this.loadingCargoValue = false;
      this.openUpdatingPricesNotification();
    },
    openUpdatingPricesNotification() {
      this.$notify({
        title: "Success",
        message: "Rates have been updated",
        type: "success",
      });
    },
    changeView(routeName) {
      this.$store.commit("carriers/disconnectSocket");
      this.$store.commit("carriers/resetCarriersList");
      this.$router
        .push({
          name: routeName,
          params: {
            quoteType: this.quoteType,
            loadId: this.$route.params.loadId,
            comesFromCarriers: true,
          },
        })
        .catch((error) => {
          if (error.name !== "NavigationDuplicated") {
            throw error;
          }
        });
    },
    openModal() {
      this.showModal = true;
    },
    openDownloadModal() {
      this.formatDocumentModal = true;
    },
    closeModal() {
      this.formatDocumentModal = false;
    },
    handleLoading(data) {
      this.loading = data;
      this.$emit("handlingAreRequestInProcess", data);
    },
    getLocationsTextStyled(title, text) {
      const textHtml = `<strong>${title}</strong> ${text}`;
      return textHtml;
    },
    async handleSelectedPlan(plan) {
      this.selectedPlan = plan;
      if (plan === "basic") {
        const pastCargoValue = this.cargoValue;
        this.cargoValue = 0;
        if (pastCargoValue > 0) {
          await this.calculateCargoValue();
        }
      }
    },
    async sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/scss/components/Modal.scss";

.load-description {
  color: $color-primary-company;
  font-family: $font-family-portal;
  margin: 20px auto;
  padding-bottom: 15px;
  margin-bottom: 0px;
}

.load-container-middle-view {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  .load-resume__right {
    width: 100%;
  }
  @media (width <= 1630px) {
    width: 100%;
  }
  @media (max-width: 800px) {
    max-width: none;
  }
}

.load-resume {
  width: 100%;
  color: $color-primary-company;
  font-weight: bold;
  text-align: left;
  display: flex;
  justify-content: space-between;
  // flex-wrap: wrap;
  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
  }
  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    height: auto;
    min-height: 265px;
    align-items: start;
    @media (max-width: 810px) {
      align-items: start;
      height: auto;
      flex-direction: column;
      position: inherit;
    }
  }
  &__left {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    overflow-wrap: break-word;
    width: 100%;
    @media (max-width: 810px) {
      width: 100%;
      max-width: none;
    }
    span {
      display: flex;
      @media (max-width: 300px) {
        display: flex;
        flex-direction: column;
      }
    }
    p {
      margin-left: 5px;
      margin-bottom: 4px;
      font-weight: 400;
    }
  }
  &__middle {
    width: 100%;
    min-width: 410px;
    @media (max-width: 800px) {
      margin-bottom: 5px;
      width: 100%;
    }
  }
  &__right {
    display: flex;
    flex-direction: column;
    width: 40%;
    @media (max-width: 810px) {
      position: inherit;
      width: 100%;
    }
  }
}
.load-information {
  max-width: 470px;
  @media (max-width: 810px) {
    width: 100%;
    max-width: none;
  }
}
.load-resume .more-content {
  min-height: 292px;
}
@media (max-width: 1203px) {
  .load-resume .more-content {
    min-height: 335px;
    align-items: flex-start;
    @media (max-width: 810px) {
      align-items: start;
      height: auto;
    }
  }
}
.selector-container span {
  font-weight: bold;
  font-family: $font-family-portal;
}

.selector-container{
  &__info{
    font-family: $font-family-portal;
    font-size: 14px;
  }
}

.load-locations {
  @media (max-width: 810px) {
    padding-right: 0px;
    width: 100%;
  }
  p {
    margin-left: 5px;
    margin-bottom: 4px;
    font-weight: 400;
  }
}

.load-name {
  display: none;
  @media (max-width: 500px) {
    display: contents;
  }
}

.edit-quote--button {
  width: 280px;
  margin-top: 10px;
  border: none;
  color: $color-white;
  background: $color-primary-trigger-button;
  border-radius: 15px;
  height: 35px;
  @include font-small-button-text;
  &:hover {
    background-color: $color-primary-trigger-button-hover;
  }
  @media (max-width: 800px) {
    width: 100%;
  }
}

.cargo-value {
  &__content {
    display: flex;
    align-items: center;
  }
}

.invalid-cargo-value {
  color: red;
  margin: 0;
  padding: 0;
  font-size: 10px;
  margin-left: 10px;
  &__input {
    background: rgb(253, 234, 234);
  }
}

.quotes-file {
  width: 100%;
  display: flex;
  flex-direction: column;
  &__button {
    @include secondary-button;
    height: 35px;
    margin: 10px auto 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    font-size: 13px;
    font-weight: 600;
    &--download {
      @include primary-button(35px);
      width: 100%;
      margin: 10px auto 0px;
    }
  }
  & .disabled {
    background-color: $color-gray;
    border: none;
    color: $color-white;
    &:hover {
      background-color: $color-gray;
    }
  }
}

.lineItems-information {
  span {
    margin: 2px 0;
  }
}
::v-deep {
  .el-collapse-item__wrap {
    border: none;
  }
  .el-collapse {
    border-top: none;
    border-bottom: none;
  }
  .el-collapse-item__header {
    background-color: rgb(246 246 246);
    font-family: $font-family-portal;
    color: $color-primary-company;
    @include font-standart-text;
    width: 100%;
    text-align: left;
    font-weight: bold;
    height: 30px;
    border: none;
  }
  .el-collapse-item__content {
    background-color: $color-background;
    font-family: $font-family-portal;
    @include font-standart-text;
    font-weight: normal;
    padding-bottom: 0%;
  }
  .row-accessorials__information {
    margin-left: 10px;
  }
  .el-icon-arrow-right:before {
    padding: 10px;
    color: $color-primary-company;
    font-weight: bold;
  }
  .el-icon-arrow-right:after {
    padding: 10px;
    color: $color-primary-company;
    font-weight: bold;
  }
  .ion-information-circle-outline {
    font-size: 1.2rem;
    margin-left: 10px;
  }
  .popover-header {
    font-family: $font-family-portal;
    color: $color-primary-company;
    font-size: 12px;
    font-weight: bold;
  }
  .popover-body {
    font-family: $font-family-portal;
    color: $color-primary-company;
    font-size: 12px;
  }
  .el-button,
  .el-button.is-plain {
    @include primary-button();
    height: 35px;
    margin: 10px auto 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    @include font-small-button-text;
  }
}

@media (max-width: 1630px) {
  .load-resume {
    // flex-direction: column;
    &__left {
      max-width: none;
    }
  }
  .load-container-middle-view {
    justify-content: inherit;
  }
}

@media (max-width: 800px) {
  .load-resume {
    &__middle {
      width: 100%;
    }
    &__right {
      width: 100% !important;
    }
  }
  .load-container-middle-view {
    flex-direction: column;
  }
  .quotes-file {
    width: 100%;
  }
}

</style>
