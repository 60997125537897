<template>
  <div class="flatbed-list">
    <template>
      <span class="divider"></span>
      <div class="flatbed-list__content">
        <div class="flatbed-list__title">
          <p class="trucs-list__type">Your flatbed options</p>
        </div>
        <div class="flatbed-container" id="flatbed-container" v-if="screenWidth > 800">
          <template v-if="carriersImages">
            <OptionsComponent
              v-for="(flatbed, index) in filteredCarriers"
              :key="index"
              :carrier="flatbed"
              :carrierImage="findCarrierImage(flatbed)"
              :aditionalInsurance="aditionalInsurance"
              :loadingInsurance="loadingInsurance"
              :originalCarrierArray="originalCarrierArray"
              :isPartial="true"
              @optionSelected="optionSelected"
              class="mb-3"
            ></OptionsComponent>
          </template>
          <template v-if="flatbed.length === 0 && !allNotificationsObtained">
            <p>We are working to obtain flatbed carriers. This may take some time.</p>
            <b-spinner small label="Spinning"></b-spinner>
          </template>
          <div class="not-carriers">
            <p v-if="flatbed.length === 0 && allNotificationsObtained">
              {{ $t("carriers.noCarriersFoundFlatbet") }}
            </p>
          </div>
        </div>
        <div class="flatbed-container" id="flatbed-container" v-if="screenWidth <= 800">
          <template v-if="carriersImages">
            <OptionsComponentResponsive
              v-for="(flatbed, index) in filteredCarriers"
              :key="index"
              :carrier="flatbed"
              :carrierImage="findCarrierImage(flatbed)"
              :aditionalInsurance="aditionalInsurance"
              :loadingInsurance="loadingInsurance"
              :originalCarrierArray="originalCarrierArray"
              :isPartial="true"
              @optionSelected="optionSelected"
              class="mb-3"
            >
            </OptionsComponentResponsive>
          </template>
          <template v-if="flatbed.length === 0 && !allNotificationsObtained">
            <p>We are working to obtain flatbed carriers. This may take some time.</p>
            <b-spinner small label="Spinning"></b-spinner>
          </template>
          <div class="not-carriers">
            <p v-if="flatbed.length === 0 && allNotificationsObtained">
              {{ $t("carriers.noCarriersFoundFlatbet") }}
            </p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import onReSize from "@/Extend/onResize";
import OptionsComponent from "../CarriersOptions/OptionsComponent.vue";
import OptionsComponentResponsive from "../CarriersOptions/OptionsComponentResponsive.vue";
import UpdateAditionalInsurance from "../../../../../../../utils/updateAditionalInsurance";

export default {
  name: "flatbed",
  mixins: [onReSize],
  props: {
    flatbed: Array,
    allNotificationsObtained: Boolean,
    filterByGuaranteed: Boolean,
    originalCarrierArray: Array
  },
  components: {
    OptionsComponent,
    OptionsComponentResponsive,
  },
  data() {
    return {
      filteredCarriers: [],
      aditionalInsurance: 35,
      loadingInsurance: false,
      carrierSelected: null,
      chosenLoad: null,
    };
  },
  created() {
    this.chosenLoad = this.$store.getters["load/getChosenLoad"];
    const cargoValue = this.$store.getters["carriers/getChosenLoadCargoValue"];
    this.aditionalInsurance = UpdateAditionalInsurance(cargoValue);
    if (this.filterByGuaranteed) {
      this.filteredCarriers = this.filterCarriersByGuaranteed(this.flatbed);
    }
  },
  watch: {
    flatbed: {
      immediate: true,
      handler() {
        this.filteredCarriers = [...this.flatbed];
      },
    },
    chosenLoadCargoValue() {
      this.aditionalInsurance = UpdateAditionalInsurance(this.chosenLoadCargoValue);
    },
  },
  computed: {
    ...mapGetters({
      chosenLoadCargoValue: "carriers/getChosenLoadCargoValue",
      carriersImages: "carriers/getCarriersImages",
    }),
  },
  methods: {
    findCarrierImage(flatbed) {
      if (flatbed.logo) {
        return { url: flatbed.logo, carrier: flatbed.carrier };
      }
      const newImage = this.carriersImages.find(
        (image) => image && image.name && flatbed.carrier && image.name.includes(flatbed.carrier)
      );
      if (newImage) {
        return newImage;
      }
      return { url: "" };
    },
    async optionSelected(params) {
      this.carrierSelected = params.carrierId;
      this.chosenLoad.insurance_required = params.isInsurance;
      this.chosenLoad.carrierName = params.carrierName;
      this.$store.commit("load/setChosenLoad", this.chosenLoad);
      if (params.changeView) {
        this.changeView("StopsInfo", params.loyaltyInsurance, params.pickup, params.isInsurance);
      }
      this.loadingInsurance = false;
    },
    filterCarriersByGuaranteed(carriers) {
      return carriers.filter((carrier) => {
        const serviceClass = carrier.serviceClass.toLowerCase();
        const serviceClassList = ["guaranteed", "expedited", "time critical", "accelerated"];
        return serviceClassList.some((item) => serviceClass.includes(item));
      });
    },
    changeView(routeName, loyaltyInsurance, pickup, insurance) {
      const id = this.carrierSelected;
      this.$router.push({
        name: routeName,
        params: {
          loadId: this.$route.params.loadId,
          carrierId: id,
          insurance
        },
        query: {
          pickup,
          insurance,
          ...(loyaltyInsurance === true && { loyaltyInsurance: true })
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.flatbed-list {
  &__content {
    width: 98%;
    margin: 20px auto;
  }
  &__type {
    margin: 0px;
  }
  &__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
    p {
      color: $color-primary-company;
      @include font-standart-text;
      font-weight: bold;
    }
    @media (max-width: 800px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 5%;
      p {
        color: $color-primary-company;
        font-weight: bold;
        margin: 0;
        margin-bottom: 3%;
      }
    }
  }
}

.divider {
  display: inline-block;
  width: 100%;
  height: 0;
  border-bottom: 1px solid $color-border-container;
  margin-top: 25px;
  @media (max-width: 800px) {
    margin: 3% 0 4% 0;
  }
}
</style>
